@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .content {
    .wrapper {
      font-size: 1.625rem;
      height: 18.75rem;
      width: 21.75rem;
    }

    .buttonWrapper {
      width: 50%;
    }
  }
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;

  .wrapper {
    background-image: url('~@Styles/images/shopping-cart.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    height: 8.75rem;
    justify-content: center;
    text-align: center;
    width: 13.75rem;
  }

  .buttonWrapper {
    margin-top: 5.75rem;
    width: 100%;
  }
}
