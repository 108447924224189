@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.going) {
  &:global(.desktop) {
    .staticArticleWrapper {
      .contentWrapper {
        max-width: 42.5rem;

        .header {
          margin: 0 auto;

          .date {
            font-size: 1.125rem;
            transform: translate(-3.5rem, -2rem);
          }

          .titleWrapper {
            margin-bottom: -8.5rem;
            padding: 4.5rem 4rem 2.5rem;
            transform: translate(-7.5rem, -7.5rem);
            width: calc(100% + 7.5rem);
          }

          .paragraphWrapper {
            li {
              margin-left: -5rem;
              width: calc(100% + 5rem);
            }
          }
        }

        .tagsWrapper {
          margin-left: -5rem;
        }
      }
    }
  }

  .staticArticleWrapper {
    .imageWrapper {
      margin-bottom: 0;
    }

    .contentWrapper {
      .header {
        .date {
          font-size: 0.75rem;
          margin-bottom: 0.25rem;
          position: relative;
          transform: translate(-0.5rem, 0);
          z-index: 2;
        }

        .titleWrapper {
          background: $staticArticle_titleBackground;
          margin: 0;
          padding: 2rem 1rem 1rem;
          position: relative;
          transform: translate(-1.5rem, -1.75rem);
          width: 100%;
          z-index: 1;
        }

        .paragraphWrapper {
          & > li {
            margin-top: 2.5rem;
          }
        }
      }

      .header > .paragraphWrapper {
        margin-top: -2.5rem;
      }

      .header > .paragraphWrapper ~ .paragraphWrapper {
        margin-top: 0;
      }
    }
  }
}

:global(.desktop) {
  .staticArticleWrapper {
    .contentWrapper {
      .header {
        .date {
          margin-bottom: 2.5rem;
        }

        .titleWrapper {
          font-size: 4.5rem;
          margin-bottom: 3.125rem;
        }

        .paragraphWrapper {
          li > div:nth-child(2) {
            padding: 1.5rem 2rem;
          }
        }
      }
    }

    .imageWrapper {
      height: 40rem;
    }
  }
}

.staticArticleWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .imageWrapper {
    border-radius: $card_borderRadius;
    height: calc(100vw - 10rem);
    margin-bottom: 4rem;
    width: 100%;
  }

  .contentWrapper {
    margin: 0 auto;
    max-width: 50rem;
    width: 100%;

    .tagsWrapper {
      margin-bottom: 5.8125rem;
    }

    .header {
      width: 100%;

      .date {
        color: $staticArticle_dateFont;
        font-size: 1.125rem;
        font-weight: normal;
      }

      .fakeDate {
        width: 20%;
      }
    }
  }
}
