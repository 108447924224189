@charset "UTF-8";

/*
 * The same for both apps
 */
@import './icons.module';
@import './bootstrap.module';

/*
 * Conditional imports
 */
@import '@Styles/defs/props.scss';
@import '@Styles/defs/colors.scss';
@import '@Styles/defs/font.scss';

html {
  overflow-x: hidden;
}

button,
input,
label,
textarea {
  font: $default_font;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

body,
h1,
h2,
h3,
h4,
h5,
p,
li,
ul {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

b,
strong {
  font-weight: $default_fontBoldWeight;
}

i {
  font-style: normal;
}
