@use 'sass:math';

$app: going;

/*
 * NUMBERS
 */
$infinity: -9999999;
$lineSize: 1px;
$size: 16;

/*
 * DEFAULT
 */
$default_solidBorder: #{math.div($size, 16)}px solid;
$default_desktopWidth: 1280px;
$default_font: 16px 'Noir Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$default_fontBoldWeight: 500;
$default_fontBoldWeightSecond: 600;
$default_fontBolderWeight: 700;
$default_fontWeight: 300;
$default_fontWeightSecond: 400;
$default_animationTime: 400ms;
$default_borderRadiusMain: 1rem;
$default_borderRadiusSmall: 0.5rem;
$default_borderRadiusTop: 0.5rem 0.5rem 0 0;
$default_borderUnderline: solid 0.125rem;
$default_boxPadding: 1.75rem 1.25rem 1.25rem;
$default_longAnimationTime: 500ms;
$default_transition: all 250ms ease-in-out;

/*
 * GLOBAL FIXED IMAGE RATIOS
 */
$fixedImage_normalWidthRatio: 405;
$fixedImage_normalHeightRatio: 350;
$fixedImage_wideWidthRatio: 1200;
$fixedImage_wideHeightRatio: 628;
$carousel_desktopWidthRatio: 1280;
$carousel_desktopHeightRatio: 350;
$carousel_mobileWidthRatio: 1;
$carousel_mobileHeightRatio: 1;

////////////////////////////////////////////////////////////////////////////////

/*
 * ARTIST
 */
$artist_borderRadius: $default_borderRadiusMain;
$artist_leftColumnWidth: calc(100% - 22rem);
$artist_rightColumnWidth: 20rem;

/*
 * BUTTON
 */
$button_radius: 10rem;
$button_background_image: none;
$button_background_image_hover: none;

/*
 * CARD
 */
$card_borderRadius: 0.5rem;
$card_borderRadiusBottom: 0 0 0.5rem 0.5rem;
$card_borderRadiusTop: 0.5rem 0.5rem 0 0;
$card_contentPadding: 1.5rem;
$card_titlePadding: 0.25rem;

/*
 * CARD DECK & CARD SLIDER
 */
$cardWidth: (
  WidthFor5: calc(20% - 1rem),
  WidthFor4: calc(25% - 1rem),
  WidthFor3: calc(33% - 1rem),
  WidthFor2: calc(50% - 1rem),
);
$cardSlider_overlayHoverTransition: visibility 1000ms, opacity $default_longAnimationTime linear;
$cardSlider_overlayIconAnimationTime: 4000ms;
$cardSlider_overlayIconAnimationDelay: 600ms;

/*
 * CAROUSEL
 */
$carousel_animationTime: $default_animationTime;
$carousel_imageMaxHeight: 24.25rem;
$carousel_imageMaxWidth: 71.875rem;

/*
 * CHEVRON
 */
$chevron_animation: 0.05s linear;

/*
 * CLIENT DATA
 */
$clientData_fbIcon: url('~@Styles/images/facebook-black.svg');

/*
 * EVENT
 */
$leftColumnWidth: 48rem;
$rightColumnWidth: 24rem;

/*
 * EVENTS
 */
$cardImage_borderRadius: 1rem 1rem 0 0;

/*
 * FILTERS
 */
$filters_width: 100%;

/*
 * GALLERY
 */
$gallery_borderRadius: $default_borderRadiusMain;
$gallery_viewerImageWidth: $default_desktopWidth;

/*
 * HEADER
 */
$header_desktopHeight: 6.5rem;
$header_menuItemsFontSize: 1.125rem;
$header_stickyDuration: 400ms;

/*
 * IMAGE_VIEWER
 */
$imageViewer_animationBackgroundTime: $default_animationTime;

/*
 * LANDING PAGE
 */
$landingPage_boldFontWeight: 900;
$landingPage_borderRadius: 0.25rem;
$landingPage_buttonBorder: #{math.div($size, 16)}px solid;
$landingPage_cardAnimation: all 0.5s ease-in-out;
$landingPage_cardBorder: #{math.div($size, 16)}px solid;
$landingPage_contentWidth: 63rem;
$landingPage_linkAnimation: all 250ms ease-out;
$landingPage_mainFont: 'Noir Pro', system-ui, sans-serif;
$landingPage_normalFontWeight: 400;
$landingPage_sectionTitleFontSize: 2rem;
$landingPage_sectionTitleMargin: 4rem auto;

/*
 * MAIN_PAGE
 */
$mainPage_boxSpacing: 1.875rem;

/*
 * MODAL
 */
$modal_desktopMinWidth: 25rem;
$modal_desktopMaxWidth: 90vw;

/*
 *  OVERBOOKING TIME SLOTS
 */
$overbooking_font_family: $default_font;

/*
 * PICK UP PARAMETERS
 */
$pickUpParameters_giftIcon: url('~@Styles/images/gift-black.svg');

/*
 * PLACE
 */
$placeLeftColumnWidth: calc(100% - 1.875rem - 26rem);
$placeRightColumnWidth: 26rem;

/*
 * SEARCH
 */
$search_buttonWidth: 7rem;
$search_inputDesktopPadding: 2.25rem;
$search_inputMobilePadding: 3rem;
$search_mobileInputSize: 3.125rem;
$search_nominalInputHeight: 4.5rem;
$search_arrowImage: url('~@Styles/images/arrow-down-grey.svg');
$search_calendarBorderRadius: 0.5rem;
$search_filterBorderRadius: 0.25rem;
$search_inputBorderRadius: 0.5rem 0 0 0.5rem;
$search_inputBorderRadiusMobile: 0.5rem;
$search_buttonBorderRadius: 0 0.5rem 0.5rem 0;

/*
 * SUBMIT BUTTON
 */
$submit_button_padding: 0.7rem 16rem 0.7rem 2.5rem;
$submit_button_background_color: none;
$submit_button_background_size: auto;
$submit_button_background_image: url('~@Styles/images/arrow.svg');
$submit_button_background-position: 95% center;

/*
 * EVENT BOX
 */
$eventBox_calendarIcon: url('~@Styles/images/going-calendar-ico.svg');
$eventBox_clockIcon: url('~@Styles/images/going-clock-ico.svg');
$eventBox_locationIcon: url('~@Styles/images/going-location-ico.svg');
$eventBox_ticketIcon: url('~@Styles/images/going-ticket-ico.svg');

/*
 * SUMMARY BOX
 */
$summary_borderSummaryBox: 1rem;
$summary_borderSummaryBoxContent: 0 0 1rem 1rem;
$summary_borderImage: 1rem 1rem 0 0;

/*
 * FOOTER
 */
$footer_phoneImage: url('~@Styles/images/going-footer-phone-image.png');

/*
 * HEART BUTTON
 */
$heart_border: math.div(1rem, 16);
$heart_size: 1rem;

/*
 * TICKETS BOX
 */
$ticketsBox_dateContainerBorder: 0.125rem solid;

/*
 * TICKETS TRANSFER
 */
$ticketsTransfer_ticketIcon: url('~@Styles/images/going-ticket-ico.svg');
