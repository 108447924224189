@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

@mixin going {
  :global(.going) {
    &:global(.desktop) {
      .summaryBox {
        .content {
          border-radius: $summary_borderSummaryBoxContent;
          box-shadow: $eventBox_summaryShadow;
          padding: 1.5rem 0;

          .title {
            padding: 0 2rem 1.5rem;
          }

          .infoBox,
          .summaryBox,
          .final {
            padding: 0 2rem;
          }

          .infoBox {
            &.slotsInfoBox {
              padding-bottom: 0;
            }
          }

          &.basket {
            border-radius: 1rem;
            margin-top: 1.5rem;

            &.basketWithoutMargin {
              margin-top: 0;
            }
          }

          &.products {
            margin-top: 0;
          }

          &.withoutPadding {
            .contentWrapper {
              padding-bottom: 0;
            }
          }

          .linkBox {
            padding: 0 2rem;
          }

          .totalPrice {
            margin-top: 1rem;
            padding: 1.5rem 2rem 0 2rem;
            align-items: center;
            font-size: 1.125rem;
            justify-content: space-between;

            .totalPriceText {
              font-size: 2.25rem;

              span {
                font-size: 1.5rem;
              }
            }

            .totalPriceTitle {
              line-height: normal;
            }
          }

          &.withoutPadding {
            padding-top: 0;
            .contentWrapper {
              padding-top: 0;
            }
          }

          .contentWrapper {
            background-color: transparent;
            border-radius: 0;
            margin-bottom: 0;
            padding: 1.5rem 2rem;
            .basketTitle {
              font-size: 1.125rem;
              margin-bottom: 1.5rem;

              .titleWrapper {
                font-size: 1.125rem;
                font-weight: 500;

                .ticketIcon {
                  align-items: flex-start;
                  background: $eventBox_ticketIcon no-repeat 0 0.3rem;
                  background-size: contain;
                }
              }
            }

            .basketRundate {
              font-size: 0.75rem;
            }

            .basketPlace {
              font-size: 0.75rem;
            }
          }

          .final {
            .text {
              .ticketIcon {
                margin-right: 0.5rem;
                padding: 0.4rem 1.25rem;
              }
            }

            .value {
              font-size: 1.5rem;
            }
          }
          .yourBasketWrapper {
            padding: 0 2rem;
            margin-bottom: 0;
            padding-bottom: 1.5625rem;
          }
        }
      }
    }

    .summaryBox {
      .content {
        padding: 1.5rem 0 0;

        &.basket {
          border-top: none;
        }

        &.transparent {
          background-color: transparent;
        }

        &.withBasket {
          border-bottom: 0.0625rem solid $basket_border;
          border-radius: 0;
        }

        .yourBasketWrapper {
          padding: 0rem 1rem;
          margin-bottom: 0.6875rem;

          .yourBasket {
            color: $basket_yourBasketText;
            font-weight: $default_fontBoldWeight;
            margin-left: 1rem;
          }
        }

        .totalPrice {
          align-items: flex-end;
          border: none;
          display: flex;
          font-size: 1.125rem;
          font-weight: 500;
          justify-content: space-between;
          padding: 0 0.5rem;

          .icon {
            margin-right: 0.5rem;
          }

          .totalPriceText {
            font-size: 2.25rem;
            font-weight: 600;
            margin-left: 0.5rem;

            span {
              font-size: 1.375rem;
              font-weight: 500;
            }
          }

          .totalPriceTitle {
            font-size: 1rem;
            font-weight: 500;
            line-height: 2.125rem;
          }
        }

        &.withoutTopPadding {
          .contentWrapper {
            padding-top: 0;
          }
        }

        .contentWrapper {
          border-radius: 0.5rem;
          margin-bottom: 2rem;
          padding: 1.5rem 1.25rem;

          &:last-of-type {
            border: none;
          }

          .basketTitle {
            display: flex;
            font-size: 1.5rem;
            font-weight: 500;
            justify-content: space-between;
            margin-bottom: 1.25rem;

            &.titleProduct {
              margin-bottom: 0;
            }

            .titleWrapper {
              display: flex;
              font-size: 1.25rem;
              font-weight: 500;
            }

            .ticketIcon {
              align-items: center;
              background: $eventBox_ticketIcon no-repeat 0 center;
              background-size: contain;
              border: none;
              display: flex;
              font-size: 0.75rem;
              font-weight: bold;
              height: auto;
              margin-right: 1rem;
              padding: 0.375rem 0.65625rem;
              width: auto;
            }

            .price {
              color: $basket_totalPriceColor;
              font-weight: 400;
              line-height: 2.125rem;
              margin-left: 0.4rem;
              min-width: 3rem;
              white-space: pre;
            }
          }

          .basketRundate {
            font-size: 0.8125rem;
            font-weight: 500;
          }

          .basketPlace {
            border-bottom: 0.125rem solid $basket_placeBorder;
            display: inline;
            font-size: 0.8125rem;
            font-weight: 500;
            line-height: 1.5;
            padding-bottom: 0.15rem;
          }
        }

        .title {
          font-size: 2rem;
          font-weight: 500;
          margin-bottom: 1.5rem;
          padding: 0 1.25rem 1.5rem;
        }

        .infoBox,
        .summaryBox,
        .final {
          padding: 0 1.25rem;
        }

        .infoBox {
          &.slotsInfoBox {
            padding-bottom: 2rem;
          }

          .info {
            font-weight: 500;
            line-height: 1.5;

            a {
              border-bottom: $default_borderUnderline $reusable_placeFont;
              font-weight: 500;
              padding-bottom: 0.15rem;
            }

            &:last-child {
              padding-bottom: 1.5rem;
            }
          }
        }

        .linkBox {
          padding: 0 2rem 1.5rem;
        }

        .summaryBox {
          .summary {
            font-size: 0.875rem;
            font-weight: 300;
            line-height: 2;

            .text {
              font-weight: 400;
            }

            .value {
              font-weight: 500;
            }
          }
        }

        .final {
          font-weight: 500;

          .text {
            font-size: 0.75rem;

            .ticketIcon {
              background: $eventBox_ticketIcon no-repeat 0 center;
              background-size: contain;
              border: none;
              height: auto;
              margin-right: 0.25rem;
              padding: 0.025rem 0.5rem;
              width: auto;
            }
          }

          .value {
            font-size: 1.125rem;
          }
        }

        .linkBox {
          margin-top: 1rem;

          a {
            border-bottom: $default_borderUnderline $reusable_placeFont;
            font-size: 0.75rem;
            font-weight: 500;
            padding-bottom: 0.15rem;
          }
        }
      }
    }
  }
}
