@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

@mixin default {
  :global(.desktop) {
    .errorModal {
      align-items: center;
      display: flex;

      .panel {
        max-width: 50%;
        min-width: 15.625rem;
      }
    }
  }

  .errorModal {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;

    &.embedError {
      align-items: flex-end;
      margin-bottom: 20vh;
    }

    .close {
      @include closeModal;
    }

    .panel {
      border-radius: 4px;
      max-width: 40rem;
      padding: 2rem;
      position: relative;

      &.panelBasket {
        background: $errors_errorModalPanelBasketBackground;
        margin-left: 0;
        margin-right: 0;
        padding: 1.875rem;
      }

      &.withIcon {
        &::before {
          background-size: cover;
          content: '';
          left: 1rem;
          position: absolute;
          z-index: 1;
        }
      }

      &.empik::before {
        background-image: url('~@Styles/images/empik-apostrophe.svg');
        height: 5.5rem;
        top: -2rem;
        width: 3rem;
      }

      &.going::before {
        background-image: url('~@Styles/images/going-square.svg');
        height: 3rem;
        top: -1rem;
        width: 3rem;
      }

      .title {
        box-sizing: border-box;
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
      }

      .titleBasket {
        display: flex;
        font-size: 1.25rem;
        font-weight: $default_fontBoldWeight;

        .basketIcon {
          margin-right: 1rem;
          position: relative;
          top: 45%;
          transform: translate(0, -50%);
        }
      }

      .confirm,
      .message {
        font-size: 1rem;
        font-weight: $default_fontBoldWeight;
        margin: 1.5rem 0;
      }

      .buttonsWrapper {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 1.5625rem;

        .buttonWrapper {
          width: 100%;

          .closeButton {
            width: 100%;
          }

          .cancelButton {
            background-color: $errors_errorModalBasketCancelButton;
            border-color: $errors_errorModalBasketCancelButton;
            margin-right: 1.25rem;

            &:hover {
              background-color: $errors_errorModalBasketCancelButton;
            }
          }
        }
      }

      .buttonWrapper {
        display: flex;
        justify-content: flex-end;

        .closeButton {
          @include primaryButton();

          font-weight: bold;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .errorModal {
      &.embedError {
        margin-bottom: 100%;
      }

      .panel {
        .titleBasket {
          font-size: 0.875rem;
        }

        .buttonsWrapper {
          flex-direction: column;

          .buttonWrapper {
            margin-bottom: 0.75rem;

            .cancelButton {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
