@font-face {
  font-display: block;
  font-family: 'app-icons';
  font-style: normal;
  font-weight: normal;
  src: url('icons/icons.eot?860g7d');
  src: url('icons/icons.eot?860g7d#iefix') format('embedded-opentype'),
    url('icons/icons.ttf?860g7d') format('truetype'), url('icons/icons.woff?860g7d') format('woff'),
    url('icons/icons.svg?860g7d#app-icons') format('svg');
}

[class^='app-icon-'],
[class*=' app-icon-'] {
  font-family: 'app-icons' !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

:global {
  .app-icon-spinner:before {
    content: '\e900';
  }
  .app-icon-cart-3:before {
    content: '\e918';
  }
  .app-icon-reload:before {
    content: '\e931';
  }
  .app-icon-premium-star:before {
    content: '\e933';
  }
  .app-icon-premium-info:before {
    content: '\e934';
  }
  .app-icon-crowd:before {
    content: '\e936';
  }
  .app-icon-sad-face:before {
    content: '\e93b';
  }
  .app-icon-info:before {
    content: '\e93c';
  }
  .app-icon-empik-door:before {
    content: '\e93e';
  }
  .app-icon-empik-cart:before {
    content: '\e940';
  }
  .app-icon-browser:before {
    content: '\e942';
  }
  .app-icon-basket:before {
    content: '\e943';
  }
  .app-icon-zoom-going:before {
    content: '\e901';
  }
  .app-icon-empik-home:before {
    content: '\e902';
  }
  .app-icon-going-calendar-empty-frame:before {
    content: '\e903';
  }
  .app-icon-going-capacity-frame:before {
    content: '\e904';
  }
  .app-icon-going-crowd-frame:before {
    content: '\e905';
  }
  .app-icon-going-group-frame:before {
    content: '\e906';
  }
  .app-icon-going-heart-frame:before {
    content: '\e907';
  }
  .app-icon-going-heart-solid:before {
    content: '\e908';
  }
  .app-icon-going-home-ico:before {
    content: '\e909';
  }
  .app-icon-going-info-frame:before {
    content: '\e90a';
  }
  .app-icon-going-location-frame:before {
    content: '\e90b';
  }
  .app-icon-going-mail-frame:before {
    content: '\e90c';
  }
  .app-icon-going-map-frame:before {
    content: '\e90d';
  }
  .app-icon-going-networking-frame:before {
    content: '\e90e';
  }
  .app-icon-going-sad-frame:before {
    content: '\e90f';
  }
  .app-icon-going-social-facebook-frame:before {
    content: '\e910';
  }
  .app-icon-going-social-google-frame:before {
    content: '\e911';
  }
  .app-icon-going-social-instagram-solid:before {
    content: '\e912';
  }
  .app-icon-going-social-twitter-solid:before {
    content: '\e913';
  }
  .app-icon-going-social-youtube-solid:before {
    content: '\e914';
  }
  .app-icon-going-tent-frame:before {
    content: '\e915';
  }
  .app-icon-going-ticket-frame:before {
    content: '\e916';
  }
  .app-icon-going-ticketmail-frame:before {
    content: '\e917';
  }
  .app-icon-going-wallet-frame:before {
    content: '\e919';
  }
  .app-icon-view-switch-icons:before {
    content: '\e91a';
  }
  .app-icon-view-switch-list:before {
    content: '\e91b';
  }
  .app-icon-filter:before {
    content: '\e91c';
  }
  .app-icon-my-account-icon:before {
    content: '\e91d';
  }
  .app-icon-search-icon:before {
    content: '\e91e';
  }
  .app-icon-empik-ticket:before {
    content: '\e91f';
  }
  .app-icon-location1:before {
    content: '\e920';
  }
  .app-icon-lorry:before {
    content: '\e921';
  }
  .app-icon-mail:before {
    content: '\e922';
  }
  .app-icon-calendar1:before {
    content: '\e923';
  }
  .app-icon-clock1:before {
    content: '\e924';
  }
  .app-icon-help:before {
    content: '\e925';
  }
  .app-icon-summary-account:before {
    content: '\e926';
  }
  .app-icon-summary-mail:before {
    content: '\e927';
  }
  .app-icon-summary-info:before {
    content: '\e928';
  }
  .app-icon-summary-ok:before {
    content: '\e929';
  }
  .app-icon-summary-ticket:before {
    content: '\e92a';
  }
  .app-icon-home:before {
    content: '\e92b';
  }
  .app-icon-home3:before {
    content: '\e92c';
  }
  .app-icon-cinema:before {
    content: '\e92d';
  }
  .app-icon-games:before {
    content: '\e92e';
  }
  .app-icon-bullhorn:before {
    content: '\e92f';
  }
  .app-icon-museum:before {
    content: '\e930';
  }
  .app-icon-barcode:before {
    content: '\e937';
  }
  .app-icon-qrcode:before {
    content: '\e938';
  }
  .app-icon-ticket-angle:before {
    content: '\e939';
  }
  .app-icon-cart:before {
    content: '\e93a';
  }
  .app-icon-credit-card:before {
    content: '\e93f';
  }
  .app-icon-lifebuoy:before {
    content: '\e941';
  }
  .app-icon-pin:before {
    content: '\e946';
  }
  .app-icon-location:before {
    content: '\e947';
  }
  .app-icon-location-invert:before {
    content: '\e948';
  }
  .app-icon-map-solid:before {
    content: '\e94b';
  }
  .app-icon-map-frame:before {
    content: '\e94c';
  }
  .app-icon-clock-frame:before {
    content: '\e94e';
  }
  .app-icon-clock-solid:before {
    content: '\e94f';
  }
  .app-icon-alarm:before {
    content: '\e950';
  }
  .app-icon-calendar:before {
    content: '\e953';
  }
  .app-icon-display:before {
    content: '\e956';
  }
  .app-icon-mobile:before {
    content: '\e958';
  }
  .app-icon-zoom-in:before {
    content: '\e987';
  }
  .app-icon-zoom-out:before {
    content: '\e988';
  }
  .app-icon-gift:before {
    content: '\e99f';
  }
  .app-icon-bar:before {
    content: '\e9a0';
  }
  .app-icon-restaurant:before {
    content: '\e9a3';
  }
  .app-icon-star-empty:before {
    content: '\e9d7';
  }
  .app-icon-star-solid:before {
    content: '\e9d9';
  }
  .app-icon-sort-amount-asc:before {
    content: '\ea4c';
  }
  .app-icon-sort-amount-desc:before {
    content: '\ea4d';
  }
  .app-icon-google:before {
    content: '\ea88';
  }
  .app-icon-google-square:before {
    content: '\ea89';
  }
  .app-icon-facebook-solid:before {
    content: '\ea90';
  }
  .app-icon-facebook-square:before {
    content: '\ea91';
  }
  .app-icon-appleinc:before {
    content: '\eabe';
  }
  .app-icon-android:before {
    content: '\eac0';
  }
  .app-icon-windows8:before {
    content: '\eac2';
  }
  .app-icon-my-ticket:before {
    content: '\eac3';
  }
  .app-icon-my-ticket-going:before {
    content: '\eac4';
  }
}
