@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

@import 'components/buying/components/FormLayout/FormLayout.module.scss';

:global(.desktop) {
  .header {
    align-items: center;
    flex-direction: row;
  }

  .title {
    font-size: 3rem;
    letter-spacing: 0rem;
  }

  .recommendation {
    font-size: 2rem;
    margin-top: unset;
  }

  .buyForm {
    padding-top: 3.625rem;

    .twoColumns {
      padding: 0;

      .fixedBox {
        width: 38%;
      }

      .form {
        margin-bottom: 5rem;
        width: 60%;
      }
    }
  }

  .disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  @media (min-width: 1075px) {
    .flexContainer {
      flex-direction: row;
    }
  }

  .price {
    width: calc(33% - 2.5rem);

    .text {
      font-size: 2rem;
    }
  }

  .reserveButton {
    width: calc(33% - 2.5rem);
  }
}

:global(.tablet) {
  .price {
    .text {
      font-size: 2rem;
    }
  }
}

:global(.going) {
  .box {
    border-radius: 1rem;
  }

  .boxTitle {
    font-weight: 500;
    text-transform: lowercase;
  }
}

.header {
  align-items: flex-start;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  width: 100%;
}

.title {
  color: $default_black;
  font-family: $default_font;
  font-size: 4rem;
  letter-spacing: 0.125rem;
}

.recommendation {
  align-items: center;
  display: flex;
  font-family: $default_font;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 2rem;
}

.userIcon {
  margin-right: 1.5rem;
}

.content {
  width: 100%;
}

.firstBox {
  border-radius: 1rem 0rem 0rem 0rem;
}

.box {
  border: none;
  box-shadow: $clientData_formPanelShadow;
  box-sizing: border-box;
  width: 100%;

  h4 {
    font-size: 1.5rem;
    padding: 1rem 0 0.5rem;
    padding-left: 0.10625rem;
  }
}

.product {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
}

.boxTitle {
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 2rem;
  padding-top: 2rem;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.summary {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.price {
  align-items: center;
  border-bottom: 0.4rem solid $default_black;
  border-top: 0.4rem solid $default_black;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  padding: 1.5rem 0;
  width: 100%;

  .text {
    font-size: 1.5rem;
  }

  .value {
    font-family: $default_font;
    font-size: 3rem;
  }
}

.buyWrapper {
  flex-direction: column;
  margin-top: 3.75rem;

  .buyButton {
    @include primaryButton();
    @include disabled(0.5);

    font-size: 1.125rem;
    font-weight: bold;
    width: 100%;
  }

  .errorContainer {
    background-color: $default_sundown;
    color: $default_red;
    font-size: 0.875rem;
    margin-top: 1.5rem;
    padding: 1rem;
    text-align: center;
  }
}
