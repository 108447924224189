@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .datePicker {
    flex: none;
    margin-bottom: 0;
    margin-right: 2.5rem;

    & > div {
      font-size: 2rem;
    }
  }
}

.datePicker {
  width: 100% !important;

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
