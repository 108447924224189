/*
 * DEFAULT COLORS
 */
$default_alto: #d8d8d8;
$default_black: #111;
$default_border: #849297;
$default_caribbeanGreen: #00db7f;
$default_darkMain: #ede40c;
$default_grey: #666666;
$default_darkGrey: #333;
$default_inputColor: #8f8f8f;
$default_lightGrey: #e0e0e0;
$default_lightMain: #f4f7f8;
$default_main: #eff200;
$default_hoverMain: #ffff57;
$default_paleMain: #f2f3ba;
$default_blue_chalk: #ebddff;
$default_premium: #000000;
$default_red: #ff1627;
$default_aqua: #00dffe;
$default_shadowColor: #bec4c5;
$default_supernova: #ffc700;
$default_white: #ffffff;
$default_whiteLilac: #f7f4fb;
$default_navy: #5a5992;
$default_granny: #869791;
$default_blue: #2699fb;
$default_darkBlue: #1766ac;
$default_sundown: #ffb4ba;
$default_osloGrey: #849297;
$default_porcelain: #e6ebed;
$default_silver: #bfbfbf;

/*
 * DEFAULT SHADOWS
 */
$default_shadow: none;
$default_shadowSmall: -0.75rem 1.25rem 0.9375rem rgba($default_shadowColor, 0.28);
$default_shadowSmallLight: 0.5rem 1rem 1rem rgba($default_shadowColor, 0.15);
$default_shadowSmallLightInverted: 0.5rem -1rem 1rem rgba($default_shadowColor, 0.15);
$default_shadowHorizontal: 2px -1px 7px #e8e5ec;
$default_shadowVertical: -1px 2px 7px #e8e5ec;

////////////////////////////////////////////////////////////////////////////////

/*
 * ABOUT APP
 */
$aboutApp_textColor: $default_premium;

/*
 * ACTION MESSAGE
 */
$actionMessage_modalBackground: rgba(0, 0, 0, 0.8);
$actionMessage_modalCancelButtonBackground: $default_lightGrey;
$actionMessage_modalCancelButtonBackgroundHover: $default_grey;
$actionMessage_modalPanelBackground: $default_white;
$actionMessage_modalPanelTitleBackground: $default_white;
$actionMessage_modalPanelFont: $default_black;
$actionMessage_modalErrorFont: $default_red;

/*
 * AGREEMENTS
 */
$agreements_formPanelBackground: $default_white;
$agreements_formPanelCheckboxLink: $default_black;
$agreements_formPanelShadow: $default_shadow;
$agreements_description: $default_grey;

/*
 * ARTIST
 */
$artist_visitorsFont: $default_red;

/*
 * ARTIST TILE
 */
$artistTile_textColor: $default_black;
$artistTile_textColorDark: $default_white;

/*
 * ONE BIG AND VERTICAL LIST OF HOTSPOTS
 */
$bigAndVerticalHotspots_mainDateColor: $default_white;
$bigAndVerticalHotspots_mainDescriptionColor: $default_white;
$bigAndVerticalHotspots_mainTitleColor: $default_main;
$bigAndVerticalHotspots_smallArticleBorder: $default_lightMain;
$bigAndVerticalHotspots_smallArticleDescriptionColor: $default_black;
$bigAndVerticalHotspots_smallArticleBackground: $default_white;
$bigAndVerticalHotspots_smallArticleHoverBackground: $default_main;
$bigAndVerticalHotspots_smallArticleHoverBorder: $default_main;
$bigAndVerticalHotspots_smallArticleTitleColor: $default_black;

/*
 * BANNER
 */
$banner_fontColor: $default_black;
$banner_lightFontColor: $default_white;

/*
 * BREADCRUMBS
 */
$breadcrumbs_link: $default_darkGrey;
$breadcrumbs_arrow: $default_main;

/*
 * BODY:
 */
$body_background: $default_lightMain;
$body_textColor: $default_black;

/*
 * BUTTON
 */
$button_borderButtonMain: $default_black;
$button_primaryButtonBackground: $default_main;
$button_primaryButtonBorder: $default_main;
$button_primaryButtonFont: $default_black;
$button_primaryButtonHoverBackground: $default_hoverMain;
$button_background_color: $default_main;
$button_background_disabled: $default_lightGrey;

/*
 * BUTTON X
 */
$buttonX_color: $default_grey;

/*
 * BUY FORM
 */
$buyForm_correctColor: green;
$buyForm_customTermAdditionalTextColor: #404040;
$buyForm_errorColor: $default_red;
$buyForm_headerFont: $default_black;
$buyForm_inputBorderColor: #ebddff;
$buyForm_autocompleteButtonColor: transparent;
$buyForm_autocompleteButtonBorderColor: $default_main;
$buyForm_autocompleteButtonHoverColor: $default_main;
$buyForm_autocompleteButtonFontColor: $default_black;
$buyForm_backgroundColor: transparent;

/*
 * CARD
 */
$card_backgroundColor: $default_white;
$card_borderColor: none;
$card_darkBackgroundColor: rgba(255, 255, 255, 0.1);
$card_darkTextColorPrimary: $default_white;
$card_darkTextColorSecondary: $default_main;
$card_linkColor: $default_black;
$card_linkUnderlineColor: $default_main;
$card_overlayBackground: rgba(0, 0, 0, 0.69);
$card_sliderArrows: $default_white;
$card_sliderArrowsHover: $default_main;
$card_sliderDarkArrows: $default_black;
$card_subtitleColor: $default_darkMain;
$card_textColorPrimary: $default_black;
$card_textColorSecondary: $default_grey;
$card_titleBackgroundColor: $default_main;
$card_whiteBackgroundColor: $default_lightMain;

/*
 * CAROUSEL
 */
$carousel_navigationDotColor: $default_grey;
$carousel_activeNavigationDotColor: $default_granny;
$carousel_titleColor: $default_white;

/*
 * CALENDAR
 */
$calendar_activeDay: $default_black;
$calendar_arrows: $default_main;
$calendar_button: $default_darkMain;
$calendar_buttonBorder: $default_border;
$calendar_chosen: $default_main;
$calendar_fontLight: $default_white;
$calendar_headers: #b9b9b9;
$calendar_hover: $default_red;
$calendar_hovered: #ff9da4;
$calendar_inactiveDay: $default_silver;
$calendar_nameOfDay: #7f7f7f;
$calendar_selected: #f1e8ff;

/*
 * DATE FILTER
 */
$dateFilter_calendarDayDisabled: $default_lightGrey;
$dateFilter_calendarDayCurrent: $default_red;
$dateFilter_calendarSelectedText: $default_black;
$dateFilter_calendarSelectedBg: $default_main;
$dateFilter_calendarHoverText: $default_black;
$dateFilter_calendarHoverBg: $default_main;
$dateFilter_calendarClose: $default_main;

/*
 * DATE PICKER
 */
$date_picker_bg_color: $default_black;
$date_picker_bg_image: rgba(0, 0, 0, 0.1);
$date_picker_color_selected: $default_black;

/*
 * DEEP_LINK_MODAL
 */
$deepLinkModal_background: $default_white;
$deepLinkModal_overlay: rgba(0, 0, 0, 0.7);

/*
 * DESCRIPTION BRICK
 */
$descriptionBrick_textColor: $default_black;
$descriptionBrick_textColorDark: $default_white;

/*
 * CHECKBOX
 */
$checkbox_background: $default_main;
$checkbox_border: $default_black;
$checkbox_selectorBorder: $default_border;
$checkbox_selectedBorder: $default_main;

/*
 * CHEVRON
 */
$chevron_color: $default_darkMain;

/*
 * CLIENT DATA
 */
$clientData_formPanelBackground: $default_white;
$clientData_formPanelShadow: $default_shadow;
$clientData_importDataBackground: $default_lightMain;
$clientData_importDataBackgroundHover: $default_main;
$clientData_importDataText: $default_black;
$clientData_moreButtonLink: $default_darkMain;
$clientData_premiumFont: $default_grey;
$clientData_premiumLink: $default_black;

/*
 * COOKIE BAR
 */
$cookieBar_background: $default_lightMain;

/*
 * EMPIK_BAR
 */
$empikBar_background: $default_black;
$empikBar_font: $default_white;
$empikBar_link: $default_white;

/*
 * ERRORS
 */
$errors_errorBackground: $default_white;
$errors_errorModalBackground: rgba(0, 0, 0, 0.8);
$errors_errorModalBasketCancelButton: $default_white;
$errors_errorModalPanelBackground: $default_white;
$errors_errorModalPanelBasketBackground: $default_lightMain;
$errors_errorModalPanelTitleBackground: $default_white;
$errors_errorModalPanelTitleFont: $default_black;
$errors_errorShadow: $default_shadow;

/*
 * GALLERY
 */
$gallery_viewerCloseBackgroundMobile: $default_white;
$gallery_closeColorMobile: $default_black;
$gallery_viewerCloseBackgroundDesktop: $default_black;
$gallery_closeColorDesktop: $default_main;
$gallery_tileAccents: $default_main;
$gallery_tileTextColor: $default_black;
$gallery_viewerBackground: rgba(0, 0, 0, 0.69);

/*
 * EVENT
 */
$event_artistBackgroundHover: $default_whiteLilac;
$event_artistBorder: $default_lightMain;
$event_artistBorderHover: $default_main;
$event_boxMoreLinkFont: $default_main;
$event_locationBackground: $default_white;
$event_locationButtonsLinkFont: $default_main;
$event_locationCoverEmptyBackground: $default_paleMain;
$event_locationCoverWaterMarkBackground: $default_paleMain;
$event_locationDescription: $default_darkGrey;
$event_locationHeader: $default_darkMain;
$event_locationOthersLinkFont: $default_darkMain;
$event_locationOthersListItemBackground: $default_lightMain;
$event_locationPlaceNameFont: $default_black;
$event_otherAboutInfoFont: $default_main;
$event_placeLinkColor: $default_black;
$event_placeNameColor: $default_black;
$event_videoWaterMarkBackground: $default_main;
$event_visitorsFont: $default_red;
$event_descriptionBackground: $default_white;
$event_descriptionTextColor: $default_darkGrey;
$event_similarEventsSectionBackground: transparent;

/*
 * EVENT BOX
 */
$eventBox_buyBarBackground: $default_white;
$eventBox_buyBarButtonBackground: $default_main;
$eventBox_buyBarButtonColor: $default_black;
$eventBox_buyBarShadow: $default_shadow;
$eventBox_buyButtonBackground: $default_main;
$eventBox_buyButtonBackgroundDisabled: $default_shadowColor;
$eventBox_buyButtonColorDisabled: $default_lightGrey;
$eventBox_buyButtonFont: $default_black;
$eventBox_buyButtonHoverBackground: $default_darkMain;
$eventBox_buyCallBoxBackground: $default_white;
$eventBox_buyCallBoxShadow: $default_shadowHorizontal;
$eventBox_buyConfirmBackground: $default_main;
$eventBox_buyConfirmBorder: $default_main;
$eventBox_buyConfirmFont: $default_white;
$eventBox_buyIcon: $default_black;
$eventBox_buyLastTicketsFont: $default_red;
$eventBox_buyPlaceFont: $default_black;
$eventBox_buyPlaceLink: $default_main;
$eventBox_buyPromoBorder: $default_border;
$eventBox_detailsBackgroundColor: $default_white;
$eventBox_detailsInfoFont: $default_black;
$eventBox_detailsInfoLink: $default_black;
$eventBox_summaryContentBackground: $default_white;
$eventBox_summaryContentShadow: none;
$eventBox_summaryFinalValueFont: $default_black;
$eventBox_summaryInfoFont: $default_black;
$eventBox_summaryFont: $default_grey;
$eventBox_summaryInfoLink: $default_darkMain;
$eventBox_summaryShadow: none;
$eventBox_triangleShadowBackground: $default_whiteLilac;
$eventBox_triangleShadowBackgroundLight: $default_white;
$eventBox_triangleShadowHorizontal: $default_shadowHorizontal;
$eventBox_triangleShadowVertical: $default_shadowVertical;
$eventBox_triangleSquareBackground: $default_main;
$eventBox_titleBorder: $default_lightGrey;
$eventBox_seeMoreButton: $default_black;
$eventBox_seeMoreButtonBorder: $default_main;

/*
 * EVENTS
 */
$events_buyButtonBackground: $default_main;
$events_buyButtonFont: $default_black;
$events_buyButtonHoverBackground: $default_hoverMain;
$events_eventBackground: $default_white;
$events_eventShadow: none;
$events_eventValueFont: $default_inputColor;
$events_imageBackground: $default_paleMain;
$events_linkColor: $default_black;
$events_loadingBackground: $default_lightMain;
$events_loadingFakeBackground: $default_paleMain;
$events_loadingShadow: $default_shadowSmall;
$events_mainFont: $default_black;
$events_moreButtonBackground: $default_shadowColor;
$events_noResultsFont: $default_paleMain;
$events_premiumBorder: $default_paleMain;
$events_premiumBadgeFont: $default_premium;
$events_premiumBadgeTooltipBackground: $default_white;
$events_premiumBadgeTooltipFont: $default_black;
$events_premiumBadgeTooltipLinkBackground: $default_premium;
$events_premiumBadgeTooltipLinkFont: $default_white;
$events_premiumBadgeTooltipShadow: $default_shadow;
$events_tagMainBackground: $default_aqua;
$events_tagMainFont: $default_white;
$events_tagOtherFont: $default_grey;
$events_tagOtherBorder: $default_lightGrey;
$events_titleLink: $default_black;
$events_titleLoaderBackground: $default_white;

/*
 * FAKE
 */
$fake_loaderBackground: $default_paleMain;

/*
 * FAQ
 */
$faq_textColor: $default_black;
$faq_textColorDark: $default_white;

/*
 * FILTERS
 */
$filters_badgesButtonBackground: $default_main;
$filters_badgesButtonClose: $default_black;
$filters_badgesButtonFont: $default_black;
$filters_badgesClearFont: $default_black;
$filters_boxBorder: $default_border;
$filters_boxFilterFont: $default_black;
$filters_inputWithIconBorder: $default_border;
$filters_moreBorder: $default_main;
$filters_moreFont: $default_black;
$filters_placeholderFont: $default_inputColor;
$filters_spanIcon: $default_black;

/*
 * FORM FIELDS
 */
$formField_correctBorder: $buyForm_correctColor;
$formField_correctColor: $buyForm_correctColor;
$formField_errorBorder: $buyForm_errorColor;
$formField_errorFont: $buyForm_errorColor;
$formField_inputBorder: $default_lightMain;
$formField_radioBorder: $default_main;
$formField_radioFont: $default_white;
$formField_radioSelectorBorder: $default_border;
$formField_buttonColor: $default_main;

/*
 * FORM.IO
 */
$formio_buttonBackgroundColor: $default_main;
$formio_buttonBackgroundActive: $default_darkMain;
$formio_buttonBackgroundHover: $default_hoverMain;
$formio_buttonFont: $default_black;
$formio_buttonBorder: $default_inputColor;
$formio_linkColor: $default_main;

/*
 * GALLERY_IMAGE_VIEWER
*/
$gallery_imageViewerBackground: rgba($default_black, 0.8);
$gallery_imageViewerButtonBorder: #aaaaaa50;
$gallery_imageViewerButtonHoverBackground: #aaaaaa12;
$gallery_imageViewerButtonHoverText: #767676;
$gallery_imageViewerButtonCloseText: #aaa;

/*
 * HAPPENING
 */
$happening_datePicker_dayBorder: $default_darkMain;
$happening_datePicker_dayColor: $default_main;
$happening_datePicker_fontColor: $default_black;
$happening_datePicker_selected: $default_main;
$happening_datePicker_selectedText: $default_black;
$happening_datePicker_hover: $default_main;
$happening_spaces_buttonBackgrond: $default_white;
$happening_spaces_buttonText: $default_black;
$happening_spaces_buttonBorder: $default_paleMain;
$happening_products_buttonBackground: $default_paleMain;
$happening_products_buttonHover: $default_main;
$happening_products_buttonColor: $default_white;
$happening_products_removeColor: $default_black;
$happening_datePicker_darkMode_fontColor: $default_white;

/*
 * HEADER
 */
$header_background: $default_white;
$header_hamburgerLine: $default_black;
$header_itemFont: $default_black;
$header_link: $default_black;
$header_shadow: 0.125rem 0.5rem 7.188rem rgba(217, 208, 230, 0.22);
$header_stickyBackground: $default_white;
$header_stickyHamburgerLine: $default_black;
$header_stickyLink: $default_black;
$header_tooltipBackground: $default_main;
$header_tooltipBorder: $default_main;

/*
 * HEART BUTTON
 */
$heart_borderColor: $default_darkGrey;
$heart_activeColor: $default_main;

/*
 * HOME SECTION
 */
$section_descriptionColor: $default_darkGrey;
$section_descriptionLightColor: $default_lightGrey;
$section_dropDownBackground: $default_white;
$section_dropDownFont: $default_darkMain;
$section_dropDownLightFont: $default_main;
$section_titleLightColor: $default_white;
$section_whiteElementBackground: $default_lightMain;

/*
 * HORIZONTAL LIST
 */
$horizontalList_articleBackground: $default_white;
$horizontalList_articleDescription: $default_darkGrey;

/*
 * HOT DEALS
 */
$hotDeals_background: $default_white;
$hotDeals_badgeDiscount: $default_caribbeanGreen;
$hotDeals_badgeFont: $default_black;
$hotDeals_badgeGift: $default_supernova;
$hotDeals_badgeImportant: $default_red;
$hotDeals_fontColor: $default_black;

/*
 * LANDING PAGE
 */
$landingPage_darkCardBackground: rgba(0, 0, 0, 0.2);
$landingPage_darkCardBackgroundHover: rgba(0, 0, 0, 0.5);
$landingPage_darkTextColor: #0d0d0d;
$landingPage_lightCardBackground: rgba(256, 256, 256, 0.2);
$landingPage_lightCardBackgroundHover: rgba(256, 256, 256, 0.5);
$landingPage_lightTextColor: #fff;

/**
 * LAYOUT
 */
$layout_contentBackground: $default_white;
$layout_contentShadow: $default_shadow;
$layout_filtersBackground: $default_white;
$layout_filtersShadow: $default_shadow;
$layout_footerBackground: $default_main;
$layout_footerLink: $default_black;
$layout_footerRowBorder: $default_darkMain;

/*
 * MODAL
 */
$modal_closeButtonBackground: $default_white;
$modal_desktopBackground: $default_white;
$modal_desktopShadow: $default_shadow;
$modal_headerBackground: $default_white;
$modal_headerShadow: 0 0 3rem #0000000d;
$modal_managerBackground: rgba(0, 0, 0, 0.8);
$modal_mobileBackground: $default_white;

/*
 * MY TICKET
 */
$myTicket_backgroundColor: $default_white;
$myTicket_iconColor: $default_black;

/*
 * PICK UP PARAMETERS
 */
$pickUpParameters_checkboxBorder: $default_black;
$pickUpParameters_checkboxPanelBackground: $default_paleMain;
$pickUpParameters_disabledBackground: $default_lightGrey;
$pickUpParameters_disabledFont: $default_grey;
$pickUpParameters_priceFont: $default_black;

/*
 * PICK UP WAY
 */
$pickUpWay_formPanelBackground: $default_white;
$pickUpWay_formPanelIconFont: $default_black;
$pickUpWay_formPanelInfoFont: $default_black;
$pickUpWay_formPanelShadow: $default_shadow;

/*
 * PLACE
 */
$place_accordionBorder: $default_main;
$place_accordionColor: $default_darkMain;
$place_boxContentBackground: $default_white;
$place_boxContentShadow: $default_shadow;
$place_boxImageShadow: none;
$place_detailColor: $default_black;
$place_detailIcon: $default_black;
$place_detailsBorder: $default_paleMain;
$place_fakeMapBackground: $default_paleMain;
$place_headersColor: $default_black;
$place_nameColor: $default_black;
$place_similarPlacesBorder: $default_main;
$place_similarPlacesCityColor: $default_main;
$place_similarPlacesNameColor: $default_black;
$place_tagsBorder: $default_lightGrey;

/*
 * PLACE CARDS
 */
$place_cardBackground: $default_white;
$place_cardTitle: $default_black;
$place_cardSubtitle: $default_darkMain;
$place_cardLink: $default_black;
$place_cardLinkUnderline: $default_main;
$place_cardEmptyCover: $default_paleMain;
$place_cardBorderColor: none;

/*
  * PLACE EVENT
  */
$placeEvent_background: $default_lightMain;
$placeEvent_noResultsFont: $default_inputColor;

/*
 * POOLS
 */
$pools_buttonBackground: $default_main;
$pools_buttonBackgroundHover: $default_main;
$pools_buttonBorder: $default_lightGrey;
$pools_buttonBorderHover: $default_main;
$pools_buttonFont: $default_black;
$pools_buttonFontHover: $default_darkMain;
$pools_descriptionArrow: $default_darkMain;
$pools_descriptionFont: $default_black;
$pools_descriptionTextColor: $default_navy;
$pools_desktopButtonBackgroundHover: $default_white;
$pools_desktopButtonBorderHover: $default_darkMain;
$pools_desktopButtonFontHover: $default_black;
$pools_loadingBackground: $default_white;
$pools_loadingShadow: -0.75rem 0.9375rem 0.9375rem rgba(210, 198, 225, 0.15);
$pools_noResultsFont: $default_grey;
$pools_poolBackground: $default_white;
$pools_poolHighlightedBorder: $default_darkMain;
$pools_poolImageFont: $default_black;
$pools_poolShadow: $default_shadow;
$pools_seatsBackground: $default_white;
$pools_seatsShadow: $default_shadowSmallLight;
$pools_selectedBackground: $default_main;
$pools_selectedBorder: $default_main;
$pools_selectedFont: $default_black;
$pools_buttonFontColor: $default_black;
$pools_buttonBorder: $default_darkMain;
$pools_selectedPool: $default_whiteLilac;

/*
 * PROGRESS_BAR
 */
$progressBar_background: $default_lightMain;
$progressBar_foreground: $default_main;

/*
 * REUSABLE
 */
$reusable_borderUnderlineColor: $default_main;
$reusable_dateFont: $default_darkGrey;
$reusable_dropdownBackground: white;
$reusable_dropDownInputShadow: none;
$reusable_dropdownShadow: none;
$reusable_imageBackground: $default_paleMain;
$reusable_imageLoadingNoImageBackground: $default_paleMain;
$reusable_loadMoreBackground: transparent;
$reusable_loadMoreBorder: $default_lightGrey;
$reusable_loadMoreFont: $default_black;
$reusable_loadMoreShadow: 0.1875rem 0.3125rem 0.75rem rgba(54, 5, 121, 0.12);
$reusable_mainTagBackground: $default_aqua;
$reusable_mainTagBorder: $default_aqua;
$reusable_mainTagFont: $default_white;
$reusable_placeFont: $default_darkMain;
$reusable_placeLink: $default_black;
$reusable_priceFont: $default_grey;
$reusable_priceFontStrong: $default_black;
$reusable_readMoreColor: $default_black;
$reusable_readMoreColorDarkBg: $default_white;
$reusable_tagFont: $default_darkGrey;
$reusable_tagBorder: $default_inputColor;
$reusable_testimonialBackground: $default_white;
$reusable_testimonialColor: $default_black;
$reusable_testimonialShadow: 0.5rem 0.5rem 2.5rem rgba(16, 16, 16, 0.1);
$reusable_titleLink: $default_black;
$reusable_tooltipTipBackground: $default_black;
$reusable_tooltipTipBorder: $default_black;
$reusable_tooltipTipFont: $default_white;
$reusable_tooltipToolFont: $default_grey;
$reusable_infoBoxBackground: $default_white;
$reusable_infoBoxBackgroundSecondary: $default_main;
$reusable_infoBoxBorderColor: none;
$reusable_infoBoxTextColor: $default_black;
$reusable_infoBoxTextColorSecondary: $default_darkMain;
$reusable_timeTableText: $default_navy;

/*
 * SCROLL
 */
$scroll_background: $default_main;
$scroll_exteriorBackground: $default_white;
$scroll_foreground: $default_black;

/*
 * SEARCH
 */
$search_buttonBackground: $default_black;
$search_buttonBorder: $default_black;
$search_buttonFont: $default_main;
$search_inputBorder: $default_main;
$search_inputBackground: $default_main;
$search_inputFont: $default_black;
$search_shadow: none;
$search_dropDownBackground: $default_white;
$search_dropDownFont: $default_black;
$search_buttonColor: $default_black;
$search_suggestBackground: $default_white;

/*
 * SEARCH 2.0
 */
$search_highlightListBackground: $default_white;
$search_highlightListCounterBackground: $default_main;
$search_highlightListBorder: $default_lightGrey;
$search_highlightIconBackground: $default_lightGrey;
$search_filterBackground: $default_white;
$search_resetIcon: $default_black;
$search_resetIconBackground: $default_main;
$search_resultsCount: $default_black;
$search_resultsCountBackground: $default_main;

/*
 * SELECTION
 */
$selection_titleBackground: $default_main;
$selection_elementBackground: $default_white;
$selection_elementDateFont: $default_grey;
$selection_elementDescriptionFont: $default_grey;

/*
 * SLIDER
 */
$slider_borderColor: $default_black;
$slider_buttonsText: $default_white;
$slider_lineColor: $default_black;
$slider_dotColor: $default_main;
$slider_textColor: $default_main;

/*
 * STATIC PAGES
 */
$staticPage_contactLink: $default_darkMain;
$staticPage_downloadText: $default_black;
$staticPage_downloadBorder: $default_alto;
$staticPage_faqBackFont: $default_grey;
$staticPage_faqLink: $default_darkMain;
$staticPage_faqListLink: $default_black;

/*
 * STATIC ARTICLE
 */
$staticArticle_authorsBackground: $default_white;
$staticArticle_dateFont: $default_black;
$staticArticle_titleBackground: $default_main;
$staticArticle_authorText: $default_black;

/*
 * SUMMARY
 */
$summary_accountBackground: $default_white;
$summary_accountIconBackground: $default_lightMain;
$summary_accountInputBorder: $default_lightGrey;
$summary_accountShadow: $default_shadow;
$summary_eventAboutFont: $default_black;
$summary_eventImageBackground: $default_white;
$summary_eventImageIcon: $default_black;
$summary_eventImageIconBackground: $default_lightMain;
$summary_summaryBackground: $default_white;
$summary_summaryContentLink: $default_black;
$summary_summaryIcon: $default_black;
$summary_summaryIconBackground: $default_darkMain;
$summary_summaryShadow: $default_shadow;

/*
 * TICKETS MANAGEMENT
 */
$tickets_linkOptionButton: $default_blue;
$tickets_linkOptionBorder: $default_blue;
$tickets_linkOptionHover: $default_darkBlue;
$tickets_formPanelBackground: $clientData_formPanelBackground;
$tickets_formPanelShadow: $clientData_formPanelShadow;
$tickets_errorColor: $default_red;
$tickets_buttonBackground: $pools_buttonBackground;
$tickets_buttonBorder: $pools_buttonBorder;
$tickets_buttonFont: $pools_buttonFont;
$tickets_desktopButtonBackgroundHover: $pools_desktopButtonBackgroundHover;
$tickets_desktopButtonBorderHover: $pools_desktopButtonBorderHover;
$tickets_desktopButtonFontHover: $pools_desktopButtonFontHover;
$tickets_selectedBackground: $pools_selectedBackground;
$tickets_selectedBorder: $pools_selectedBorder;
$tickets_selectedFont: $pools_selectedFont;

/*
 * TICKETS BOX
 */
$ticketsBox_BackgroundMain: $default_white;
$ticketsBox_ButtonBackground: $default_red;
$ticketsBox_ButtonText: $default_white;
$ticketsBox_defaultText: $default_darkGrey;
$ticketsBox_iconColor: $default_black;
$ticketsBox_Border: $default_lightGrey;
$ticketsBox_Shadow: transparent;
$ticketsBox_placeName: $default_black;

/*
 * TIME SLOTS
 */
$tickets_changeDateTimeSlotButtonBackground: $default_white;
$tickets_changeDateTimeSlotButtonBackgroundHover: $default_red;
$tickets_changeDateTimeSlotButtonBorder: $default_black;
$tickets_changeDateTimeSlotButtonColor: $default_black;

/*
 * TIMELINE
 */
$timeline_border: $default_black;
$timeline_borderDark: $default_white;
$timeline_marker: $default_main;
$timeline_markerDark: $default_black;
$timeline_text: $default_black;
$timeline_textDark: $default_white;

/*
 * WIDE ARTICLE
 */
$wideArticle_articleBackground: $default_white;
$wideArticle_articleDescription: $default_darkGrey;
$wideArticle_articleShadow: none;
$wideArticle_loadingBackground: $default_white;
$wideArticle_loadingShadow: $default_shadowSmall;
$wideArticle_loadingFakeBackground: $fake_loaderBackground;

/*
 * BASKET
 */

$basket_border: $default_porcelain;
$basket_button: $default_white;
$basket_buttonText: $default_black;
$basket_cancelIcon: $default_grey;
$basket_contentBackground: transparent;
$basket_deleteItem: $default_silver;
$basket_infoContainer: $default_grey;
$basket_itemBackground: $default_white;
$basket_itemsLengthBackground: $default_main;
$basket_itemsLengthColor: $default_black;
$basket_link: $default_black;
$basket_placeBorder: $default_main;
$basket_poolContainerBorderColor: $default_porcelain;
$basket_porcelain: $default_porcelain;
$basket_pricePerItem: $default_black;
$basket_serviceFee: $default_black;
$basket_summaryPrice: $default_grey;
$basket_ticketAmount: $default_black;
$basket_totalPrice: $default_black;
$basket_totalPriceBorderColor: $default_main;
$basket_totalPriceColor: $default_black;
$basket_white: $default_white;
$basket_yourBasketBorderColor: $default_whiteLilac;
$basket_yourBasketText: $default_black;

/*
 * ADDITIONAL FIELDS
 */
$additionalFields_poolName: $default_grey;
