@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.going) {
  .description {
    button {
      border-bottom: $default_borderUnderline;
      font-weight: 500;
      padding-bottom: 0.15rem;
    }

    .descriptionText {
      font-size: 0.75rem;
      font-weight: 300;
      line-height: 1.3;
    }

    .arrow {
      &.down {
        top: 0.1rem;
        transform: rotate(45deg);
      }

      &.up {
        top: 0.25rem;
        transform: rotate(-135deg);
      }
    }
  }
}

:global(.tablet),
:global(.mobile) {
  .description {
    button {
      align-self: center;
    }
    .descriptionText {
      text-align: center;
    }
  }
}

.description {
  line-height: 0.75rem;

  button {
    background: none;
    border: none;
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    position: relative;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .descriptionText {
    display: inline-block;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }

  .arrow {
    border: solid;
    border-width: 0 0.125rem 0.125rem 0;
    display: inline-block;
    margin-left: 0.1875rem;
    padding: 0.125rem;
    position: absolute;
    right: -0.75rem;

    &.down {
      top: 0.25rem;
      transform: rotate(45deg);
    }

    &.up {
      top: 0.4375rem;
      transform: rotate(-135deg);
    }
  }
}
