@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/functions.scss';

:global(.going) {
  .header {
    font-weight: 500;
    text-transform: lowercase;
  }

  .agreements {
    .formPanel {
      .agreement {
        border-radius: 1rem;
      }
    }
  }
}

:global(.empik) {
  &:global(.desktop) {
    .agreements {
      .formPanel {
        .agreement {
          border-radius: 0;

          .agreementsContainer {
            .title {
              font-size: 1.5rem;
            }

            .description {
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }

  .agreements {
    .formPanel {
      .agreement {
        border-radius: 0;
        box-shadow: $default_shadowSmallLight;

        .agreementsContainer {
          .title {
            font-weight: $default_fontBolderWeight;
          }

          .description {
            font-weight: $default_fontBoldWeight;
          }
        }
      }
    }
  }
}

:global(.desktop) {
  .agreements {
    .formPanel {
      .agreement {
        padding: 2.375rem 1.875rem;

        .agreementsContainer {
          margin: 0 -1.875rem;
          margin-bottom: 2.375rem;
          padding: 0 1.875rem;
          padding-bottom: 2.375rem;

          .title {
            font-size: 1.25rem;
            font-weight: $default_fontBoldWeight;
          }

          .description {
            color: $agreements_description;
            font-size: 0.75rem;
          }
        }
      }
    }
  }

  .requiredText {
    margin-left: 2.375rem;
  }
}

.agreements {
  @include disabledClass();

  margin-top: 3.75rem;
  width: 100%;

  &.withoutTopMargin {
    margin-top: 0;
  }

  .formPanel {
    border-radius: borderRadius(0, 0, 0, 0);
    box-sizing: border-box;
    padding: 0;
    width: 100%;

    .agreement {
      &:first-of-type {
        margin-top: 0;
      }

      box-shadow: $agreements_formPanelShadow;
      margin-top: 2rem;
      padding: 1.75rem 1.25rem;

      .agreementsContainer {
        border-bottom: 0.0625rem solid $default_porcelain;
        margin: 0 -1.25rem;
        margin-bottom: 1.75rem;
        padding: 0 1.25rem;
        padding-bottom: 1.75rem;

        .title {
          font-size: 1.25rem;
          font-weight: $default_fontBoldWeight;
        }

        .description {
          color: $agreements_description;
          font-size: 0.75rem;
        }
      }
    }
  }
}

.header {
  font-size: 1.75rem;
  margin-bottom: 2rem;

  &:before {
    content: attr(data-number) '. ';
  }
}

.formPanel {
  border: none;

  .checkbox {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    .newsletterText {
      font-size: 0.75rem;
      font-weight: 300;
      line-height: 1.3;
      margin-top: 1rem;
    }
  }
}

.requiredText {
  font-size: 0.875rem;
  font-weight: 300;
  margin-left: 1.75rem;
  margin-top: 0.875rem;
}

.requiredTextAdditional {
  margin-top: 0;
}
