@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

@mixin default {
  :global(.empik) {
    &:global(.desktop) {
      .summaryBox {
        .content {
          &.basket {
            border-radius: 0 0 0.625rem 0;

            .contentWrapper {
              padding: 1.5rem;

              .basketTitle {
                margin-bottom: 1.5rem;

                &.titleProduct {
                  margin-bottom: 0;
                }

                .titleWrapper {
                  align-items: flex-start;

                  .ticketIcon {
                    background: $eventBox_ticketIcon no-repeat 0;
                    background-size: contain;
                    margin-top: 0.3rem;
                    padding: 0.475rem 0.75625rem;
                  }
                }

                .price {
                  line-height: 1.5rem;
                }
              }
            }
          }

          .totalPrice {
            margin-top: 1rem;
            padding: 0rem 1.5rem;
            padding-bottom: 1.75rem;
          }

          &.withoutTopPadding {
            .contentWrapper {
              padding-top: 0;
            }
          }
        }

        .yourBasketWrapper {
          padding: 0rem 1.5rem;
          margin-bottom: 0;
        }
      }
    }

    .summaryBox {
      .content {
        &.basket {
          border-radius: 0 0 0.625rem 0.625rem;

          .contentWrapper {
            margin-bottom: 0.75rem;

            .basketRundate,
            .basketPlace {
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  :global(.desktop) {
    .summaryBox {
      .content {
        border-radius: 0;
        box-shadow: $eventBox_summaryShadow;
        padding: 1.5rem 2rem;

        &.withoutTopPadding {
          padding-top: 0;
        }

        &.summaryEventInfo {
          margin-bottom: 0.75rem;
        }

        .totalPrice {
          justify-content: space-between;
        }

        .contentWrapper {
          .poolsWrapper {
            border: none;
            margin: 0;
            padding: 0;
          }
        }

        .infoBox {
          .info {
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }

  .wrapper {
    height: 100%;

    .sticky {
      margin-bottom: 1.5rem;
      position: sticky;
      top: 1.5rem;
      width: 100%;
    }
  }

  .summaryBox {
    border-radius: $summary_borderSummaryBox;
    height: 100%;

    .wrapper {
      .imageWrapper {
        background-color: $eventBox_summaryContentBackground;
        border-radius: $summary_borderImage;
      }
    }

    .summary {
      .text {
        color: $eventBox_summaryFont;
      }
    }

    .yourBasketWrapper {
      padding: 0rem 1rem;
      padding-bottom: 1.5rem;

      .yourBasket {
        align-items: center;
        color: $basket_yourBasketText;
        display: flex;
        font-size: 1.125rem;
        font-weight: 700;
        margin-left: 1.5rem;
      }

      .icon {
        width: 1.3125rem;
      }

      .itemsLength {
        font-size: 0.875rem;
        height: 1.25rem;
        left: 0.8rem;
        width: 1.25rem;
      }
    }

    .content {
      border-radius: 0.625rem;
      box-shadow: $eventBox_summaryContentShadow;
      padding: 1.625rem 1.5625rem 1.625rem 1.75rem;

      &.withoutTopPadding {
        .contentWrapper {
          padding-top: 0;
        }
      }

      &.basket {
        border-top: 0.0625rem solid $basket_porcelain;
        box-shadow: none;
        padding: 0;

        &.products {
          border-top: none;
        }
      }

      &.withBasket {
        margin-bottom: 2.75rem;
      }

      .totalPrice {
        align-items: center;
        display: flex;
        font-size: 0.875rem;
        font-weight: 500;
        justify-content: space-between;
        padding: 2rem 1.25rem;

        .icon {
          margin-right: 0.5rem;
        }

        .totalPriceTitle {
          font-size: 1rem;
          font-weight: $default_fontBolderWeight;
        }

        .totalPriceText {
          font-size: 1.75rem;
          font-weight: 900;
          margin-left: 0.5rem;
        }
      }

      .contentWrapper {
        padding: 1.5rem 0 1.9375rem;

        &:last-of-type {
          border: none;
        }

        .basketTitle {
          display: flex;
          font-size: 1.5rem;
          font-weight: 500;
          justify-content: space-between;
          margin-bottom: 1.25rem;

          &.titleProduct {
            margin-bottom: 0;
          }

          .titleWrapper {
            display: flex;
            font-size: 1.25rem;
            font-weight: 900;

            .productIcon {
              height: 1.5rem;
              margin-right: 1rem;
              width: 1.5rem;
            }
          }

          .ticketIcon {
            background: $eventBox_ticketIcon no-repeat 0 0.5rem;
            background-size: contain;
            border: none;
            display: flex;
            font-size: 0.75rem;
            height: auto;
            margin-right: 1rem;
            padding: 0.625rem 0.85rem;
            width: auto;
          }

          .price {
            color: $basket_totalPriceColor;
            font-size: 1rem;
            font-weight: 900;
            margin-left: 0.4rem;
            min-width: 3rem;
            white-space: pre;
          }
        }

        .basketRundate {
          font-size: 0.75rem;
          font-weight: $default_fontBoldWeight;
        }

        .basketPlace {
          border-bottom: 0.125rem solid $basket_placeBorder;
          display: inline;
          font-size: 0.75rem;
          font-weight: $default_fontBoldWeight;
          line-height: 1.5;
          padding-bottom: 0.15rem;
        }

        .poolsWrapper {
          border-top: 0.0625rem solid $basket_border;
          margin-left: -1.25rem;
          margin-right: -1.25rem;
          margin-top: 1.75rem;
          padding-left: 1.25rem;
          padding-right: 1.25rem;
        }
      }

      .title {
        font-size: 1.25rem;
        margin-bottom: 1.125rem;
      }

      .infoBox {
        font-size: 1rem;
        font-weight: bold;

        .info {
          font-size: 0.875rem;
          margin-bottom: 0.3rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .summaryBox {
        font-size: 0.75rem;
        line-height: 1.125rem;
        margin-bottom: 3rem;
        margin-top: 2rem;

        .summary {
          display: flex;
          justify-content: space-between;
          margin: 0;

          .value {
            font-weight: bold;
          }
        }
      }

      .final {
        align-items: baseline;
        display: flex;
        font-weight: bold;
        justify-content: space-between;

        .text {
          font-size: 1rem;
        }

        .value {
          color: $eventBox_summaryFinalValueFont;
          font-size: 1.5rem;
        }
      }
    }
  }
}
