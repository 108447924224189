@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.empik) {
  &:global(.desktop) {
    .basketWrapper {
      min-height: 80vh;
      width: 100%;

      .content {
        background-color: transparent;
      }
    }

    .buyButton {
      font-size: 1.125rem;
      font-weight: $default_fontBoldWeight;
    }
  }

  .basketWrapper {
    .yourBasketWrapper {
      padding-left: 1rem;

      .yourBasket {
        align-items: center;
        display: flex;
        font-size: 1.125rem;
        font-weight: $default_fontBolderWeight;
        margin-left: 1.5rem;
      }

      .icon {
        width: 1.3125rem;
      }

      .itemsLength {
        font-size: 0.875rem;
        height: 1.25rem;
        left: 0.8rem;
        width: 1.25rem;
      }
    }

    .content {
      border-radius: 0.625rem;
    }
  }
}

:global(.desktop) {
  .basketWrapper {
    .yourBasketWrapper {
      margin-bottom: 2.5rem;
      margin-top: 2.875rem;

      .yourBasket {
        font-size: 1.75rem;
        font-weight: $default_fontBoldWeight;
      }
    }

    .icon {
      font-size: 1.5rem;
      height: 1.75rem;
    }

    .itemsLength {
      left: 0.9rem;
    }

    .buttonsContainer {
      justify-content: flex-end;

      .buttonsWrapper {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0;
        width: 100%;

        .buttonWrapper {
          width: 48%;
        }
      }
    }
  }

  .ticketIcon {
    padding: 0.625rem 1.5rem;
  }
}

.basketWrapper {
  width: 100%;
  padding: 0 !important;
  .yourBasketWrapper {
    margin-bottom: 1.4375rem;
    margin-top: 1.6875rem;

    .yourBasket {
      color: $basket_yourBasketText;
      font-weight: $default_fontBoldWeight;
      margin-left: 1rem;
    }
  }

  .buttonsContainer {
    display: flex;

    .buttonsWrapper {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      width: 100%;

      .buttonWrapper {
        width: 100%;

        .basketButton {
          background-color: $basket_button;
          border-color: $basket_button;
          color: $basket_buttonText;
          margin-bottom: 0.875rem;

          &:hover {
            background-color: $basket_button;
          }
        }
      }
    }
  }
}

.ticketIcon {
  background: $eventBox_ticketIcon no-repeat 0 center;
  background-size: contain;
  border: none;
  font-size: 0.75rem;
  height: auto;
  margin-right: 1rem;
  padding: 0.625rem 1rem;
  width: auto;
}

.buyButton {
  @include primaryButton();

  font-size: 1.25rem;
  font-weight: $default_fontBoldWeight;
  margin-bottom: 1.375rem;
  padding: 1.25rem 0;
  width: 100%;
}
