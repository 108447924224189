@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

.checkbox {
  @include checkbox;

  label {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    font-weight: 500;
    line-height: 1.5rem;
  }

  .selector {
    flex: 0 0 1.5rem;
  }

  .error {
    bottom: -1.5rem;
    color: $formField_errorFont;
    font-size: 0.75rem;
    left: 0;
  }
}
